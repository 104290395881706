.tech-statement {
    max-width: 800px;
    margin: 60px auto;
}

.styled-heading {
    font-size: 42px;
    font-weight: 700 !important;
    color: #40a278;
    -webkit-text-stroke: 1px #40a278;
}

.styled-heading2 {
    font-size: 30px;
    font-weight: 700 !important;
    color: #40a278;
    -webkit-text-stroke: 1px #40a278;
    text-align: center;
}

.styled-p {
    margin-bottom: 30px;
    font-size: 17px !important;
    text-align: center;
    font-family: open sans, "sans-serif";
    // font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
}


.text-style {
    max-width: 600px;
    margin: 0 auto;
}

.text-style2 {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.styled-divider {
    margin: 2rem;
    border: 2px solid #9c6c29;
}

.styled-divider2 {
    margin: 1rem;
    border: 1px solid #9c6c29;
}
hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .35;
}

.sectionBg1 {
    background-color: #f8f4f4;;
}