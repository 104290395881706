
:root {
    --font-base: "DM Sans", sans-serif;
  
    --primary-color: #edf2f8;
    --secondary-color: #313bac;
    --black-color: #030303;
    --lightGray-color: #e4e4e4;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: #ffffff;
  }

  section {
    scroll-behavior: smooth;
    scroll-margin-top: 100px;
  }
  
  
  .app__navbar {
    width: 100%;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 1rem 2rem;


    // background: rgba(255, 255, 255, 0.65);
    // background: rgba(255, 255, 255, 0.95);
    // border-bottom: .5px solid #000;
  
    -webkit-backdrop-filter: blur(4px);
  
    position: fixed;
    z-index: 2;
  }
  
  .app__navbar-scroll {
    background: rgba(255, 255, 255, 1.00);
    background: linear-gradient(90deg,transparent 0,#f8f4f4 15%,#f8f4f4 25%,#f8f4f4 0,#f8f4f4 35%,#f8f4f4 0,#f8f4f4 50%,#f8f4f4 65%,#f8f4f4 0,#f8f4f4 75%,#f8f4f4 0,#f8f4f4 85%,transparent);
    backdrop-filter: blur(4px);
    // border: 1px solid rgba(255, 255, 255, 0.18);
    border-bottom: 2px solid #dbc7ab;
  }
  
  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    img {
      //width: 90px;
      //height: 20px ;
      // width: 150px;
      width: 80px;
      width: 40px;
      width: 32px;
      //width: 176px;
  
      @media screen and (min-width: 2000px) {
        width: 180px;
        height: 40px;
      }
    }
  }


  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-right: 160px;
    margin-right: 70px;
  
    li {
      margin: 0 1rem;
      cursor: pointer;
  
      flex-direction: column;
  
      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
  
        margin-bottom: 5px;
      }
  
      a {
        //color: var(--gray-color);
        text-decoration: none;
        flex-direction: column;
        // text-transform: uppercase;
        font-weight: 500;
        background-color: #fff;
        // background-color: transparent;
        transition: all 0.3s ease-in-out;
        
        //color: white;
        //text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
        //1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
  
        &:hover {
          color:var(--secondary-color);
          color: #dbc7ab;
          color: #ab9c86;
          color: #54a582;
        }
      }
  
      &:hover {
        div {
          background-color: var(--secondary-color);
          background-color: transparent;
        }
      }
    }
  
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .nav-link-test li a {
    background: transparent;
    text-align: center;
  }
  
  .navbar__links-color {
    color: #fff;
  }
  
  .navbar__links-color-scroll {
    color: #000;
    color: #938672;
    color: #563E1E;
  }
  
  
  
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #40a278;
    background-color: #9c6c29;
  
    svg {
      width: 70%;
      height: 70%;
      color: var(--white-color);
    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
  
      padding: 1rem;
  
      width: 80%;
      height: 100vh;
  
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
  
      //background: url('../../assets/bgWhite.png');
      background-color: var(--white-color);
      background-size: cover;
      background-repeat: repeat;
  
      box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
      border-left: 3px solid #9c6c29;
  
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        color: #887a65;
        
        margin: 0.5rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        // align-items: flex-start;
        align-items: center;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: #9c6c29;
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            // font-weight: 500;
            font-weight: 700;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
  
        @media screen and (min-width: 900px) {
          display: none;
        }
      }
  
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  